<template>
  <div>
    <base-confirm
      v-if="visible"
      @close="$emit('close')"
      @confirmed="onConfirmed"
      size="max-w-xl"
      message="Apakah anda yakin data yang dibuat sudah benar?"
      type="success"
      icon-type="badge"
      title="Buat Faktur"
      confirm-text="Ya"
      cancel-text="Tutup"
      visible
    >
    </base-confirm>
    <loading v-if="withLoading && loading" />
  </div>
</template>

<script>
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  components: { BaseConfirm },
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    orderId: String,
    withLoading: {
      type: Boolean,
      default: true
    }
  },
  emits: ['close', 'confirmed', 'submit'],
  data() {
    return {
      loading: false,
      description: null
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onConfirmed() {
      this.loading = true;

      this.$emit('submit')

      const [, err] = await this.request(
        `/api/v1/orders/${this.orderId}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'orders',
              id: this.orderId,
              attributes: {
                print_invoice_enabled: false
              }
            }
          }
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.$emit('confirmed');
      }

      this.loading = false;
    },
  },
};
</script>
