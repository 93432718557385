<template>
  <p>
    {{ date | formatDate }}
    <span class="tooltip text-red-600" v-if="diff">
      *
      <span class="tooltiptext" style="width: 120px">
        {{ this.order.attributes.createdAt | formatDate }}
      </span>
    </span>
  </p>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    order: Object,
  },
  computed: {
    date() {
      return this.order.attributes.orderedAt || this.order.attributes.createdAt;
    },
    diff() {
      if (!this.order.attributes.orderedAt) {
        return false;
      }

      return !dayjs(this.order.attributes.orderedAt).isSame(this.order.attributes.createdAt)
    },
  },
};
</script>
