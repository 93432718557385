var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-y-4"},[_c('base-card',{attrs:{"title":"Tambah Koli","description":"Tambahkan barang yang akan dikemas"}},[_c('div',{staticClass:"space-y-4"},[_vm._l((_vm.packets),function(packet,packetIndex){return _c('div',{key:packetIndex,staticClass:"space-y-4 rounded-lg border border-gray-200 bg-white p-6"},[_c('div',{staticClass:"item-stretch flex gap-4"},[_c('base-input',{attrs:{"fullwidth":"","inset":"","label":"Nama Koli","type":"text"},model:{value:(_vm.packets[packetIndex].name),callback:function ($$v) {_vm.$set(_vm.packets[packetIndex], "name", $$v)},expression:"packets[packetIndex].name"}}),_c('base-button',{attrs:{"disabled":_vm.packets.length < 2,"custom-height":"h-auto","self-end":false,"color":_vm.packets.length > 1 ? 'danger-secondary' : 'gray'},on:{"click":function($event){return _vm.onRemovePacket(packetIndex)}}},[_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":"heroicons:trash-solid"}}),_vm._v(" Hapus Koli ")],1)],1),_c('datatable',{attrs:{"columns":[
            {
              code: 'kode barang',
              name: 'Kode Barang',
            },
            {
              code: 'nama barang',
              name: 'Nama Barang',
            },
            {
              code: 'jumlah',
              name: 'Jumlah',
            },
            {
              code: 'jumlah batch',
              name: 'Jumlah Batch',
            },
            {
              code: 'aksi',
              name: 'Aksi',
              theadClass: 'text-center',
            },
          ]},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return [(!packet.products.length)?[_c('tr',{class:classes.tr},[_c('td',{class:classes.td,attrs:{"colspan":"5"}},[_c('button',{staticClass:"flex w-full flex-col items-center space-y-2 py-4 text-center text-gray-400 hover:text-gray-500",on:{"click":function($event){return _vm.onAddPacketProduct(packetIndex)}}},[_c('Icon',{staticClass:"h-6 w-6",attrs:{"icon":"heroicons:plus-circle"}}),_c('p',{staticClass:"text-xs font-normal"},[_vm._v(" Tidak ada data"),_c('br'),_vm._v("Klik untuk menambahkan ")])],1)])])]:_vm._l((packet.products),function(product,index){return _c('tr',{key:index,class:classes.tr},[_c('td',{class:classes.td},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(_vm.getProductDetail(product.orderDetailOrBonusId) .attributes.product_code)+" "),(
                        _vm.getProductDetail(product.orderDetailOrBonusId)
                          .type === 'order-detail-bonuses'
                      )?_c('span',[_vm._v("(BONUS)")]):_vm._e()])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getProductDetail(product.orderDetailOrBonusId).attributes .product_name)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(product.qty)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(product.batches.length)+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('button',{on:{"click":function($event){return _vm.onRemovePacketProduct(packetIndex, index)}}},[_c('Icon',{staticClass:"h-4 w-4 text-red-500",attrs:{"icon":"heroicons:trash-solid"}})],1)])])})]}}],null,true)}),(packet.products.length)?_c('div',{staticClass:"flex justify-end"},[_c('base-button',{attrs:{"size":"sm","color":"white"},on:{"click":function($event){return _vm.onAddPacketProduct(packetIndex)}}},[_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":"heroicons:plus-solid"}}),_vm._v(" Tambah Produk ")],1)],1):_vm._e()],1)}),_c('div',{staticClass:"flex justify-end"},[_c('base-button',{on:{"click":_vm.onAddPacket}},[_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":"heroicons:plus-solid"}}),_vm._v(" Tambah Koli ")],1)],1)],2)]),_c('base-card',{attrs:{"title":"Sisa Barang","description":"Sisa barang yang belum dikemas"}},[_c('datatable',{attrs:{"columns":[
        {
          code: 'kode barang',
          name: 'Kode baranB',
        },
        {
          code: 'nama barang',
          name: 'Nama Barang',
        },
        {
          code: 'jumlah',
          name: 'Jumlah',
        },
      ]},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return [(!_vm.productsRemainder.data.length)?[_c('tr',{class:classes.tr},[_c('td',{class:classes.td,attrs:{"colspan":"3"}},[_c('div',{staticClass:"flex flex-col items-center space-y-2 py-4 text-center"},[_c('Icon',{staticClass:"h-6 w-6 text-gray-400",attrs:{"icon":"heroicons:check-circle"}}),_c('p',{staticClass:"text-xs font-normal text-gray-400"},[_vm._v(" Tidak ada data barang"),_c('br'),_vm._v("yang belum dikemas ")])],1)])])]:_vm._l((_vm.productsRemainder.data),function(product){return _c('tr',{key:`${product.type}-${product.id}`,class:classes.tr},[_c('td',{class:classes.td},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(product.attributes.product_code)+" "),(product.type === 'order-detail-bonuses')?_c('span',[_vm._v("(BONUS)")]):_vm._e()])]),_c('td',{class:classes.td},[_vm._v(_vm._s(product.attributes.product_name))]),_c('td',{class:classes.td},[_vm._v(_vm._s(product.attributes.product_qty))])])})]}}])})],1),_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-button',{attrs:{"disabled":!_vm.valid},on:{"click":function($event){_vm.visibleSubmit = true}}},[_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":"heroicons:archive-box-solid"}}),_vm._v(" Siap Dikemas ")],1),_vm._t("action")],2),_c('select-packet-product-modal',{attrs:{"products":_vm.productsRemainder,"packets":_vm.packets,"visible":_vm.selectPacketProductModal.visible,"batch-mode":_vm.batchMode,"order-id":_vm.orderId,"product-boxes":_vm.productBoxes,"product-qr-codes":_vm.productQrCodes},on:{"close":function($event){_vm.selectPacketProductModal.visible = false},"confirmed":_vm.onConfirmedPacketProduct}}),_c('base-confirm',{attrs:{"size":"max-w-xl","message":_vm.productsRemainder.data.length
        ? 'Barang yang sisa akan dimasukkan ke Susulan, apakah anda yakin akan melanjutkan?'
        : 'Apakah anda yakin data yang dibuat sudah benar?',"type":_vm.productsRemainder.data.length ? 'warning' : 'success',"icon-type":"badge","title":_vm.productsRemainder.data.length ? 'Barang sisa ditemukan' : 'Buat Koli',"confirm-text":"Ya","cancel-text":"Tutup","visible":_vm.visibleSubmit},on:{"close":function($event){_vm.visibleSubmit = false},"confirmed":_vm.onSubmit}}),(_vm.order)?_c('vue-html2pdf',{ref:"printStockMovement",attrs:{"show-layout":false,"float-layout":true,"preview-modal":true,"enable-download":false,"paginate-elements-by-height":5000,"filename":_vm.order.data.attributes.origin_code,"pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"210mm"}},[_c('LayoutPrintStockMovement',{attrs:{"slot":"pdf-content","stock-movement":_vm.printStockMovementAttributes},slot:"pdf-content"})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }