<template>
  <div>
    <loading v-if="loading" />
    <base-confirm
      @close="$emit('close')"
      @confirmed="onValidate"
      size="max-w-xl"
      :message="
        hasRemainder
          ? 'Barang yang sisa akan dimasukkan ke Susulan, apakah anda yakin akan melanjutkan?'
          : 'Apakah anda yakin data yang dibuat sudah benar?'
      "
      :type="hasRemainder ? 'warning' : 'success'"
      icon-type="badge"
      :title="hasRemainder ? 'Barang sisa ditemukan' : stockMovement?.attributes?.shipment_type === 'pickup' ? 'Siap Diambil' : 'Siap Kirim'"
      confirm-text="Ya"
      cancel-text="Tutup"
      :visible="visible"
    >
      <template v-if="hasRemainder" #content>
        <base-input
          inset
          label="Alasan Acc Atasan"
          placeholder="Alasan Acc Atasan"
          tag="textarea"
          rows="4"
          v-model="accReason"
        />
      </template>
    </base-confirm>
  </div>
</template>
<script>
import { requestMixin } from '@/mixins/request/request';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseConfirm,
  },
  props: {
    indexPath: String,
    visible: Boolean,
    stockMovement: Object,
    order: Object
  },
  emits: ['close', 'validated'],
  data() {
    return {
      accReason: null,
      loading: false
    };
  },
  computed: {
    hasStockMovement() {
      return !!this.stockMovement;
    },
    hasRemainder() {
      if (!this.hasStockMovement) {
        return false;
      }

      const totalOrderDetail = this.getIncludedByType(
        this.order,
        'order-details'
      ).reduce(
        (prev, current) => prev + current.attributes.product_not_packed,
        0
      );
      const totalBonus = this.getIncludedByType(
        this.order,
        'order-detail-bonuses'
      ).reduce((prev, current) => prev + current.attributes.bonus_qty, 0);

      const shipped = this.stockMovement.attributes.packets.reduce(
        (prev, current) => {
          const shipped = current.products.reduce(
            (prev, current) => prev + current.shipped_product_qty,
            0
          );

          return prev + shipped;
        },
        0
      );

      return totalOrderDetail + totalBonus !== shipped;
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onValidate() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/stock-movements/${this.stockMovement.id}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'stock-movements',
              id: this.stockMovement.id,
              attributes: {
                is_valid_for_shipment: true,
                acc_shipment_reason: this.accReason,
              },
            },
          },
        }
      );

      if (!err) {
        this.$emit('validated')
      } else {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      }

      this.loading = false;
    },
  }
};
</script>
