<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-7xl"
      @opened="onOpened"
      @close="onClosed"
    >
      <base-card
        title="Daftar Barang"
        description="Data barang dari order yang telah dibuat"
        :with-style="false"
      >
        <div class="space-y-4 border-t pt-5">
          <div class="flex items-center justify-end gap-4">
            <ToggleButton
              v-if="batchMode === 'input'"
              :labels="{
                checked: 'QR Kurang',
                unchecked: 'QR Tambah',
              }"
              :width="96"
              color="#dc2626"
              v-model="qrScan.isDecrementQty"
            />
            <base-input
              :shadow="false"
              placeholder="Cari kode"
              v-model="search"
            />
          </div>

          <datatable
            :columns="[
              ...(batchMode === 'input'
                ? [
                    {
                      id: 'check',
                      name: '',
                      theadClass: 'pr-0',
                    },
                  ]
                : []),
              {
                id: 'qrcode',
                name: '',
                theadClass: 'pr-0',
              },
              {
                code: 'code',
                name: 'Kode Barang',
              },
              {
                code: 'name',
                name: 'Nama Barang',
              },
              {
                code: 'expire_at',
                name: '',
              },
              {
                code: 'total',
                name: 'Total Produk',
              },
              {
                code: 'qty',
                name: 'Jumlah',
              },
              {
                code: 'action',
                name: '',
                theadClass: 'text-right',
              },
            ]"
          >
            <template #tbody="{ classes }">
              <template v-if="!products.data.length">
                <td colspan="7" :class="classes.td">
                  <div
                    class="flex flex-col items-center space-y-2 py-4 text-center"
                  >
                    <Icon
                      icon="heroicons:check-circle"
                      class="h-6 w-6 text-gray-400"
                    />
                    <p class="text-xs font-normal text-gray-400">
                      Tidak ada data barang<br />yang belum dikemas
                    </p>
                  </div>
                </td>
              </template>
              <template v-else>
                <template v-for="(product, index) in products.data">
                  <tr
                    :key="`product-${index}`"
                    :class="[
                      classes.tr,
                      product.attributes.stock_qty < 1 ? 'bg-gray-50' : '',
                    ]"
                    :title="
                      product.attributes.stock_qty < 1
                        ? 'Produk tidak memiliki stok'
                        : ''
                    "
                  >
                    <td
                      v-if="batchMode === 'input'"
                      :class="[classes.td, 'pr-0']"
                    >
                      <base-checkbox
                        :input-checked="!!selectedProducts[index].qty"
                        disabled
                        :with-label="false"
                      />
                    </td>
                    <td :class="[classes.td, 'pr-0']">
                      <Icon
                        v-if="checkProductHasQr(product)"
                        icon="heroicons:qr-code"
                        class="h-4 w-4"
                      />
                    </td>
                    <td :class="classes.td">
                      <p
                        class="flex items-center gap-2 font-medium text-gray-900"
                      >
                        {{ product.attributes.product_code }}
                        <span v-if="product.type === 'order-detail-bonuses'"
                          >(BONUS)</span
                        >
                        <Icon
                          v-if="product.attributes.stock_qty < 1"
                          icon="heroicons:exclamation-triangle-solid"
                          class="h-4 w-4 text-yellow-600"
                        />
                      </p>
                    </td>
                    <td :class="classes.td" colspan="2">
                      {{ product.attributes.product_name }}
                    </td>
                    <td :class="classes.td">
                      {{ product.attributes.product_qty }}
                    </td>
                    <td :class="classes.td">
                      {{ selectedProducts[index].qty ?? 0 }}
                    </td>
                    <td :class="classes.td">
                      <div
                        v-if="
                          selectedProducts[index].batches.length ||
                          selectedProducts[index].manualBatchesOptions.length
                        "
                        class="flex justify-end"
                      >
                        <button @click="onToggleBatchProductVisible(index)">
                          <Icon
                            :icon="
                              batchProductVisible.includes(index)
                                ? 'heroicons:chevron-down'
                                : 'heroicons:chevron-right'
                            "
                            class="h-4 w-4"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <template v-if="batchProductVisible.includes(index)">
                    <tr
                      v-for="(batch, batchIndex) in selectedProducts[index]
                        .batches"
                      :key="`product-${index}-batch-${batchIndex}`"
                      :class="[classes.tr, 'bg-gray-50']"
                    >
                      <td v-if="batchMode === 'input'" :class="classes.td">
                        <base-checkbox
                          :input-checked="
                            !!selectedProducts[index].batches[batchIndex].qty &&
                            selectedProducts[index].batches[batchIndex].qty > 0
                          "
                          disabled
                          :with-label="false"
                        />
                      </td>
                      <td :class="classes.td"></td>
                      <td :class="classes.td">
                        <base-button
                          v-if="
                            batchMode === 'input' && checkProductHasQr(product)
                          "
                          size="sm"
                          :color="
                            checkBatchScanStatus(index, batchIndex)
                              ? 'warning'
                              : 'white'
                          "
                          @click="onToggleBatchScanQr(index, batchIndex)"
                        >
                          {{
                            checkBatchScanStatus(index, batchIndex)
                              ? 'Batal Scan QR'
                              : 'Scan QR'
                          }}
                        </base-button>
                        <base-radio
                          v-else-if="batch.batchType === 'qrcode'"
                          label=""
                          :val="{
                            isDecrementQty: false,
                            productIndex: index,
                            batchIndex: batchIndex,
                          }"
                          v-model="qrScan"
                        />
                      </td>
                      <td :class="classes.td">{{ batch.code }}</td>
                      <td :class="classes.td">
                        {{ batch.expire_at | formatDate }}
                      </td>
                      <td :class="classes.td">{{ batch.stock }}</td>
                      <td :class="classes.td">
                        <input
                          type="text"
                          class="block border-0 bg-transparent p-0 text-sm placeholder-gray-300 focus:ring-0"
                          placeholder="Masukkan jumlah"
                          :value="
                            selectedProducts[index].batches[batchIndex].qty
                          "
                          :disabled="batch.batchType !== 'manual'"
                          @change="
                            (e) => onChangeBatchQty(e, index, batchIndex)
                          "
                        />
                      </td>
                      <td :class="classes.td"></td>
                    </tr>
                    <tr
                      v-if="
                        selectBatchQrModal.options.length &&
                        selectedProducts[index].qty <
                          product.attributes.product_qty
                      "
                      :key="`${index}-add-qr-batch`"
                      :class="classes.tr"
                    >
                      <td :class="classes.td"></td>
                      <td :class="classes.td">
                        <button
                          class="flex w-full items-center justify-center gap-2 text-green-600 hover:text-green-500"
                          @click="onOpenSelectBatchQrModal(index)"
                        >
                          <Icon icon="heroicons:plus-circle" class="h-6 w-6" />
                          QR
                        </button>
                      </td>
                    </tr>
                    <tr
                      v-if="selectedProducts[index].manualBatchesOptions.length"
                      :key="`${index}-add-manual-batch`"
                      :class="classes.tr"
                    >
                      <td :class="classes.td"></td>
                      <td :class="classes.td">
                        <button
                          class="flex w-full items-center justify-center gap-2 text-green-600 hover:text-green-500"
                          @click="onOpenSelectBatchManualModal(index)"
                        >
                          <Icon icon="heroicons:plus-circle" class="h-6 w-6" />
                          Manual
                        </button>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </datatable>

          <div class="flex justify-end gap-2">
            <base-button @click="onConfirm">Konfirmasi</base-button>
            <base-button color="white" @click="$emit('close')"
              >Kembali</base-button
            >
          </div>
        </div>
      </base-card>
    </base-modal>

    <!-- modal pilih batch qr -->
    <base-modal
      :showing="selectBatchQrModal.visible"
      @close="selectBatchQrModal.visible = false"
    >
      <base-card :with-style="false" title="Pilih Batch">
        <datatable
          :columns="[
            { id: 'name', name: 'No Batch' },
            { id: 'expire', name: 'Kadaluarsa' },
            { id: 'stock', name: 'Stok' },
          ]"
        >
          <template #tbody="{ classes }">
            <tr
              v-for="stockBatch in selectBatchQrModal.options"
              :key="stockBatch.id"
              :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
              @click="onSelectBatchQr(stockBatch)"
            >
              <td :class="classes.td">{{ stockBatch.code }}</td>
              <td :class="classes.td">
                {{ stockBatch.expire_at | formatDate }}
              </td>
              <td :class="classes.td">{{ stockBatch.stock | toCurrency }}</td>
            </tr>
          </template>
        </datatable>
      </base-card>
    </base-modal>

    <!-- modal pilih batch manual -->
    <base-modal
      :showing="selectBatchManualModal.visible"
      @close="selectBatchManualModal.visible = false"
    >
      <base-card :with-style="false" title="Pilih Batch">
        <datatable
          :columns="[
            { id: 'name', name: 'No Batch' },
            { id: 'expire', name: 'Kadaluarsa' },
            { id: 'stock', name: 'Stok' },
          ]"
        >
          <template #tbody="{ classes }">
            <tr
              v-for="stockBatch in selectBatchManualModal.options"
              :key="stockBatch.id"
              :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
              @click="onSelectBatchManual(stockBatch)"
            >
              <td :class="classes.td">{{ stockBatch.code }}</td>
              <td :class="classes.td">
                {{ stockBatch.expire_at | formatDate }}
              </td>
              <td :class="classes.td">{{ stockBatch.stock | toCurrency }}</td>
            </tr>
          </template>
        </datatable>
      </base-card>
    </base-modal>

    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseRadio from '@/components/base/BaseRadio.vue';
import { ToggleButton } from 'vue-js-toggle-button';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    ToggleButton,
    BaseCheckbox,
    BaseRadio,
  },
  props: {
    orderId: String,
    products: Object,
    packets: Array,
    visible: Boolean,
    productBoxes: Array,
    productQrCodes: Array,
    batchMode: {
      type: String,
      default: 'input',
    },
  },
  emits: ['close', 'confirmed'],
  data() {
    return {
      batchProductVisible: [],
      loadingScanBatch: false,
      search: null,
      selectedProducts: [],
      selectBatchQrModal: {
        options: [],
        index: null,
        visible: false,
        qty: 1,
      },
      selectBatchManualModal: {
        options: [],
        index: null,
        visible: false,
        qty: 1,
      },
      qrScan: {
        isDecrementQty: false,
        productIndex: null,
        batchIndex: null,
      },
    };
  },
  computed: {
    loading() {
      return this.loadingScanBatch;
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    calculateSelectedProductQty(index) {
      this.selectedProducts[index].qty = this.selectedProducts[index].batches
        .filter((batch) => !!batch.qty && Number(batch.qty) > 0)
        .reduce((total, batch) => (total += Number(batch.qty)), 0);
    },
    checkProductHasQr(product) {
      const productDetail = this.getSingleIncluded(
        this.products,
        product.relationships.product.data.id
      );

      if (
        productDetail.attributes.qrcode &&
        productDetail.attributes.is_qrcode_active
      ) {
        return true;
      }

      if (this.batchMode === 'input') {
        const hasBoxes = this.productBoxes.some(
          (box) => box.product.uuid === productDetail.id
        );
        const hasQrCodes = this.productQrCodes.some(
          (qr) => qr.product.uuid === productDetail.id
        );

        return hasBoxes || hasQrCodes;
      }

      return false;
    },
    checkBatchScanStatus(index, batchIndex) {
      if (this.qrScan.batchIndex === null) {
        return false;
      }

      if (this.qrScan.productIndex !== index) {
        return false;
      }

      return this.qrScan.batchIndex === batchIndex;
    },
    getProductStockBatches(productId, productType) {
      return this.products.meta.stock_batches.filter(
        (batch) =>
          batch.product.uuid === productId && batch.product_type == productType
      );
    },
    getStockBatchUsedQty(batchId, productType) {
      return this.packets
        .filter((packet) =>
          packet.products.some((product) =>
            product.batches.some(
              (batch) =>
                batch.id === batchId && batch.product_type === productType
            )
          )
        )
        .reduce((total, packet) => {
          return (
            total +
            packet.products.reduce((total, product) => {
              return (
                total +
                product.batches
                  .filter(
                    (batch) =>
                      batch.id === batchId && batch.product_type === productType
                  )
                  .reduce((total, batch) => total + batch.qty, 0)
              );
            }, 0)
          );
        }, 0);
    },
    getQrObject(qrCode) {
      const product = this.products.data[this.qrScan.productIndex];
      const productDetail = this.getSingleIncluded(
        this.products,
        product.relationships.product.data.id
      );

      if (
        productDetail.attributes.qrcode === qrCode &&
        productDetail.attributes.is_qrcode_active
      ) {
        return {
          detail: productDetail,
          amount: 1,
        };
      }

      const productBox = this.productBoxes.find(
        (box) => box.product.uuid === productDetail.id && box.qrcode === qrCode
      );

      if (productBox) {
        return {
          detail: productBox,
          amount: productBox.product_count,
        };
      }

      const productQrCode = this.productQrCodes.find(
        (qrcode) =>
          qrcode.product.uuid === productDetail.id && qrcode.qrcode === qrCode
      );

      if (productQrCode) {
        const productQrCodeUsedInCurrentPacket = this.selectedProducts.some(
          (product) =>
            product.batches.some((batch) =>
              batch.qrCodes.includes(productQrCode.qrcode)
            )
        );
        const productQrCodeUsedInOtherPacket = this.packets.some((packet) =>
          packet.products.some((product) =>
            product.batches.some((batch) =>
              batch.qrCodes.includes(productQrCode.qrcode)
            )
          )
        );

        if (
          !productQrCodeUsedInCurrentPacket &&
          !productQrCodeUsedInOtherPacket
        ) {
          return {
            type: 'product-qr',
            detail: productQrCode,
            amount: 1,
          };
        }
      }

      return null;
    },
    onToggleBatchProductVisible(index) {
      if (this.batchProductVisible.includes(index)) {
        this.batchProductVisible.splice(
          this.batchProductVisible.indexOf(index),
          1
        );
      } else {
        this.batchProductVisible.push(index);
      }
    },
    onChangeBatchQty(e, index, batchIndex) {
      const qty = Number(e.target.value);
      const { stock, qty: batchQty } =
        this.selectedProducts[index].batches[batchIndex];
      const { qty: inputQty } = this.selectedProducts[index];
      const { product_qty: productQty } = this.products.data[index].attributes;

      const reamainder = productQty - (inputQty - batchQty);

      if (isNaN(qty) || qty > stock || qty < 0 || qty > reamainder) {
        e.target.value = this.selectedProducts[index].batches[batchIndex].qty;
      } else {
        this.selectedProducts[index].batches[batchIndex].qty = Number(
          e.target.value
        );

        this.calculateSelectedProductQty(index);
      }
    },
    onConfirm() {
      const selectedProducts = this.selectedProducts.filter(
        (product) => !!product.qty && Number(product.qty) > 0
      );
      const packetProducts = selectedProducts.map((selectedProduct) => {
        const productDetail = this.products.data.find(
          (productDetail) =>
            productDetail.id === selectedProduct.orderDetailOrBonusId
        );

        return {
          orderDetailOrBonusId: selectedProduct.orderDetailOrBonusId,
          type: selectedProduct.type,
          productId: selectedProduct.productId,
          qty: Math.min(
            Number(selectedProduct.qty),
            Number(productDetail.attributes.product_qty)
          ),
          batches: selectedProduct.batches
            .filter((batch) => !!batch.qty && Number(batch.qty) > 0)
            .map((batch) => ({
              ...batch,
              qty: Number(batch.qty),
            })),
        };
      });

      document.removeEventListener('barcode-scan', this.onQrScanned);
      this.$emit('confirmed', packetProducts);
    },
    onOpened() {
      this.batchProductVisible = [];
      this.qrScan.productIndex = null;
      this.qrScan.batchIndex = null;
      this.selectedProducts = this.products.data.map((product) => ({
        orderDetailOrBonusId: product.id,
        type: product.type,
        productId: product.attributes.product_id,
        qty: null,
        manualBatchesOptions: this.getProductStockBatches(
          product.attributes.product_id,
          product.type === 'order-details' ? 'reguler' : 'free'
        ).map((batch) => {
          return {
            id: batch.id,
            code: batch.code,
            product_type: batch.product_type,
            stock:
              batch.stock_qty -
              this.getStockBatchUsedQty(
                batch.id,
                product.type === 'order-details' ? 'reguler' : 'free'
              ) -
              batch.stock_used_qty,
            qty: null,
            expire_at: batch.expire_at,
            qrCodes: [],
          };
        }),
        batches:
          this.batchMode === 'input'
            ? this.getProductStockBatches(
                product.attributes.product_id,
                product.type === 'order-details' ? 'reguler' : 'free'
              ).map((batch) => {
                return {
                  id: batch.id,
                  code: batch.code,
                  product_type: batch.product_type,
                  stock:
                    batch.stock_qty -
                    this.getStockBatchUsedQty(
                      batch.id,
                      product.type === 'order-details' ? 'reguler' : 'free'
                    ) -
                    batch.stock_used_qty,
                  qty: null,
                  expire_at: batch.expire_at,
                  qrCodes: [],
                };
              })
            : [],
      }));

      document.addEventListener('barcode-scan', this.onQrScanned);
    },
    onClosed() {
      document.removeEventListener('barcode-scan', this.onQrScanned);

      this.$emit('close');
    },
    onToggleBatchScanQr(index, batchIndex) {
      if (this.checkBatchScanStatus(index, batchIndex)) {
        this.qrScan.productIndex = null;
        this.qrScan.batchIndex = null;
      } else {
        this.qrScan.productIndex = index;
        this.qrScan.batchIndex = batchIndex;
      }
    },
    onQrScanned(e) {
      if (this.batchMode === 'input') {
        this.scanProduct(e.detail.text);
      } else {
        this.scanBatch(e.detail.text);
      }
    },
    onOpenSelectBatchQrModal(index) {
      this.selectBatchQrModal.index = index;
      this.selectBatchQrModal.visible = true;
    },
    onOpenSelectBatchManualModal(index) {
      this.selectBatchManualModal.index = index;
      this.selectBatchManualModal.options = this.selectedProducts[
        index
      ].manualBatchesOptions.map((batch) => ({
        id: batch.id,
        code: batch.code,
        product_type: batch.product_type,
        stock: batch.stock,
        expire_at: batch.expire_at,
        qrCodes: [],
      }));
      this.selectBatchManualModal.visible = true;
    },
    onSelectBatchQr(batch) {
      const index = this.selectBatchQrModal.index;

      this.selectedProducts[index].batches.push({
        id: batch.id,
        code: batch.code,
        product_type: batch.product_type,
        stock: batch.stock,
        qty: this.selectBatchQrModal.qty,
        expire_at: batch.expire_at,
        qrCodes: [],
        batchType: 'qrcode',
      });

      if (!this.batchProductVisible.includes(index)) {
        this.batchProductVisible.push(index);
      }

      this.calculateSelectedProductQty(index);

      this.qrScan.productIndex = index;
      this.qrScan.batchIndex = this.selectedProducts[index].batches.length - 1;

      this.selectBatchQrModal.options = this.selectBatchQrModal.options.filter(
        (batch) =>
          this.selectedProducts[index].batches.every(
            (selectedBatch) => selectedBatch.id !== batch.id
          )
      );
      this.selectBatchQrModal.visible = false;
    },
    onSelectBatchManual(batch) {
      const index = this.selectBatchManualModal.index;

      this.selectedProducts[index].batches.push({
        id: batch.id,
        code: batch.code,
        product_type: batch.product_type,
        stock: batch.stock,
        qty: 1,
        expire_at: batch.expire_at,
        qrCodes: [],
        batchType: 'manual',
      });

      this.calculateSelectedProductQty(index);

      this.selectedProducts[index].manualBatchesOptions = this.selectedProducts[
        index
      ].manualBatchesOptions.filter(
        (manualBatch) => manualBatch.id !== batch.id
      );
      this.selectBatchManualModal.visible = false;
    },
    async scanBatch(qrCode) {
      const qrCodeUsed = this.packets.some((packet) =>
        packet.products.some((product) =>
          product.batches.some((batch) => batch.qrCodes.includes(qrCode))
        )
      );

      if (qrCodeUsed) {
        this.createAlert({
          status: 'error',
          data: 'Qr Code sudah digunakan',
          timeout: 1000,
        });
      } else {
        this.loadingScanBatch = true;

        const [res, err] = await this.request(
          `/api/v1/orders/${this.orderId}/-actions/get-batch-by-qrcode`,
          {
            method: 'get',
            params: {
              qrcode: qrCode,
            },
          }
        );

        if (err) {
          this.createAlert({
            status: 'error',
            data: this.getRequestErrorMessage(err),
            timeout: 1000,
          });
        } else {
          const {
            product_uuid: productId,
            stock_batch: batch,
            stock_batches: batches,
            qty: qrQty,
          } = res;
          const orderDetail = this.products.data.find(
            (product) => product.attributes.product_id === productId
          );
          const selectedProductIndex = this.selectedProducts.findIndex(
            (selected) => selected.productId === productId
          );

          if (this.qrScan.productIndex !== selectedProductIndex) {
            this.qrScan.productIndex = selectedProductIndex;
            this.qrScan.batchIndex = this.selectedProducts[
              selectedProductIndex
            ].batches.filter((batch) => batch.batchType === 'qrcode').length
              ? 0
              : null;
          }

          const selectedProduct = this.selectedProducts[selectedProductIndex];

          const remainderQty =
            orderDetail.attributes.product_qty - selectedProduct.qty;

          if (remainderQty < 1) {
            this.createAlert({
              status: 'error',
              data: 'Jumlah melebihi batas',
              timeout: 1000,
            });
          } else {
            const qty = Math.min(qrQty, remainderQty);

            if (!batch) {
              if (this.qrScan.batchIndex === null) {
                this.selectBatchQrModal.options = batches
                  .filter((batch) =>
                    this.selectedProducts[selectedProductIndex].batches.every(
                      (selectedBatch) => selectedBatch.id !== batch.id
                    )
                  )
                  .map((batch) => ({
                    id: batch.id,
                    code: batch.code,
                    product_type: batch.product_type,
                    stock:
                      batch.stock_qty -
                      this.getStockBatchUsedQty(
                        batch.id,
                        orderDetail.type === 'order-details'
                          ? 'reguler'
                          : 'free'
                      ) -
                      batch.stock_used_qty,
                    expire_at: batch.expire_at,
                    qrCodes: [],
                  }))
                  .filter((batch) => batch.stock >= qty);

                this.selectBatchQrModal.qty = qty;
                this.selectBatchQrModal.index = selectedProductIndex;

                if (this.selectBatchQrModal.options.length === 1) {
                  this.onSelectBatchQr(this.selectBatchQrModal.options[0]);
                } else {
                  this.selectBatchQrModal.visible = true;
                }
              } else {
                const currentBatch =
                  this.selectedProducts[selectedProductIndex].batches[
                    this.qrScan.batchIndex
                  ];
                const remainderStock = currentBatch.stock - currentBatch.qty;

                if (remainderStock < 1) {
                  this.createAlert({
                    status: 'error',
                    data: 'Jumlah melebihi batas',
                    timeout: 1000,
                  });
                } else {
                  this.selectedProducts[selectedProductIndex].batches[
                    this.qrScan.batchIndex
                  ].qty += Math.min(remainderStock, qty);
                  this.calculateSelectedProductQty(selectedProductIndex);
                }
              }
            } else {
              if (qrQty > remainderQty) {
                this.createAlert({
                  status: 'error',
                  data: 'Jumlah melebihi batas',
                  timeout: 1000,
                });
              } else {
                const selectedBatchIndex = this.selectedProducts[
                  selectedProductIndex
                ].batches.findIndex(
                  (selectedBatch) => selectedBatch.id === batch.id
                );

                const stock =
                  batch.stock_qty -
                  this.getStockBatchUsedQty(
                    batch.id,
                    orderDetail.type === 'order-details' ? 'reguler' : 'free'
                  ) -
                  batch.stock_used_qty;

                if (stock < 1) {
                  this.createAlert({
                    status: 'error',
                    data: 'Jumlah melebihi batas',
                    timeout: 1000,
                  });
                } else {
                  if (selectedBatchIndex !== -1) {
                    const selectedBatch =
                      this.selectedProducts[selectedProductIndex].batches[
                        selectedBatchIndex
                      ];

                    if (selectedBatch.qrCodes.includes(qrCode)) {
                      this.createAlert({
                        status: 'error',
                        data: 'Qr Code sudah digunakan',
                        timeout: 1000,
                      });
                    } else {
                      const currentBatch =
                        this.selectedProducts[selectedProductIndex].batches[
                          selectedBatchIndex
                        ];

                      if (currentBatch.qty >= currentBatch.stock) {
                        this.createAlert({
                          status: 'error',
                          data: 'Jumlah melebihi batas',
                          timeout: 1000,
                        });
                      } else {
                        this.selectedProducts[selectedProductIndex].batches[
                          selectedBatchIndex
                        ].qrCodes.push(qrCode);
                        this.selectedProducts[selectedProductIndex].batches[
                          selectedBatchIndex
                        ].qty += qty;
                      }
                    }
                  } else {
                    this.selectedProducts[selectedProductIndex].batches.push({
                      id: batch.id,
                      code: batch.code,
                      product_type: batch.product_type,
                      batchType: 'unique_qrcode',
                      stock,
                      qty,
                      expire_at: batch.expire_at,
                      qrCodes: [qrCode],
                    });
                  }

                  if (
                    !this.batchProductVisible.includes(selectedProductIndex)
                  ) {
                    this.batchProductVisible.push(selectedProductIndex);
                  }

                  this.calculateSelectedProductQty(selectedProductIndex);
                }
              }
            }
          }
        }

        this.loadingScanBatch = false;
      }
    },
    scanProduct(qrCode) {
      if (this.qrScan.batchIndex === null) {
        return;
      }

      const qr = this.getQrObject(qrCode);

      if (qr !== null) {
        const { amount, type, detail } = qr;

        const product = this.selectedProducts[this.qrScan.productIndex];
        const productDetail = this.products.data[this.qrScan.productIndex];
        const selectedBatch =
          this.selectedProducts[this.qrScan.productIndex].batches[
            this.qrScan.batchIndex
          ];

        if (type === 'product-qr') {
          this.selectedProducts[this.qrScan.productIndex].batches[
            this.qrScan.batchIndex
          ].qrCodes.push(detail.qrcode);
        }

        const isDecrementQty =
          type !== 'product-qr' && this.qrScan.isDecrementQty;

        if (isDecrementQty) {
          const qty = Math.min(amount, selectedBatch.qty);

          if (qty === 0) {
            this.selectedProducts[this.qrScan.productIndex].batches[
              this.qrScan.batchIndex
            ].qty = null;
          } else {
            this.selectedProducts[this.qrScan.productIndex].batches[
              this.qrScan.batchIndex
            ].qty -= qty;
          }
        } else {
          if (
            selectedBatch.qty < selectedBatch.stock &&
            selectedBatch.qty < productDetail.attributes.product_qty
          ) {
            const qty = Math.min(
              amount,
              selectedBatch.stock,
              selectedBatch.stock - selectedBatch.qty,
              productDetail.attributes.product_qty - product.qty
            );

            this.selectedProducts[this.qrScan.productIndex].batches[
              this.qrScan.batchIndex
            ].qty += qty;
          }
        }

        this.calculateSelectedProductQty(this.qrScan.productIndex);
      }
    },
  },
};
</script>
