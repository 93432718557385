<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <shipment-order-detail-card :order="order" />

      <base-card
        title="Informasi Barang Keluar"
        description="Daftar barang yang akan dikirim"
      >
        <datatable
          :columns="[
            {
              id: 'kode barang',
              name: 'Kode Barang',
            },
            {
              id: 'nama barang',
              name: 'Nama Barang',
            },
            {
              id: 'jumlah',
              name: 'Jumlah',
              theadClass: 'text-right',
            },
            {
              id: 'harga',
              name: 'Harga',
              theadClass: 'text-right',
            },
            {
              id: 'harga total',
              name: 'Harga Total',
              theadClass: 'text-right',
            },
          ]"
        >
          <template #tbody="{ classes }">
            <tr
              v-for="product in products"
              :key="`${product.type}-${product.id}`"
              :class="classes.tr"
            >
              <td :class="classes.td">
                <p class="font-bold text-gray-900">
                  {{ product.attributes.product_code }}
                  <span v-if="product.type === 'order-detail-bonuses'"
                    >(BONUS)</span
                  >
                </p>
              </td>
              <td :class="classes.td">{{ product.attributes.product_name }}</td>
              <td :class="[classes.td, 'text-right']">
                {{
                  (product.type === 'order-details'
                    ? product.attributes.product_qty
                    : product.attributes.bonus_qty) | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  (product.type === 'order-details'
                    ? product.attributes.product_price
                    : 0) | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  (product.type === 'order-details'
                    ? product.attributes.total_price
                    : 0) | toCurrency
                }}
              </td>
            </tr>
          </template>
        </datatable>
      </base-card>

      <div class="flex justify-between">
        <div class="flex gap-2">
          <template
            v-if="
              !hasStockMovement ||
              !stockMovement.attributes.is_valid_for_shipment
            "
          >
            <base-button color="white" @click="cancelOrderVisible = true">
              <Icon icon="heroicons:x-mark" class="h-4 w-4" />
              Batalkan Faktur Penjualan
            </base-button>
            <base-button
              color="white"
              v-if="hasStockMovement"
              @click="deleteVisible = true"
            >
              <Icon icon="heroicons:x-mark" class="h-4 w-4" />
              Batalkan Faktur Kemasan
            </base-button>
          </template>
        </div>

        <div class="flex items-center gap-2">
          <div v-if="hasStockMovement" class="flex gap-2">
            <template v-if="!stockMovement.attributes.is_valid_for_shipment">
              <base-button @click="onPrintReceipt">
                <Icon icon="heroicons:printer-solid" class="h-4 w-4" />
                Cetak Surat Tanda Terima
              </base-button>
              <base-button @click="validateVisible = true">
                <Icon icon="heroicons:truck-solid" class="h-4 w-4" />
                {{ stockMovement.attributes.shipment_type === 'pickup' ? 'Siap Diambil!' : 'Siap Kirim!' }}
              </base-button>
            </template>
          </div>
          <div v-else>
            <base-button
              v-if="order.data.attributes.print_invoice_enabled"
              @click="disableOrderInvoiceVisible = true"
            >
              <Icon icon="heroicons:printer-solid" class="h-4 w-4" />
              Cetak SPPB
            </base-button>
            <base-button
              v-else
              :to="`${indexPath}/${order.data.id}/pengemasan`"
            >
              <Icon icon="heroicons:archive-box-solid" class="h-4 w-4" />
              Rincian Koli
            </base-button>
          </div>

          <base-button color="white" :to="indexPath">
            <Icon icon="heroicons:arrow-uturn-left" class="h-4 w-4" />
            Kembali
          </base-button>
        </div>
      </div>
    </div>

    <cancel-order-shipment-confirmation
      :visible="cancelOrderVisible"
      :order-id="order.data.id"
      :has-stock-movement="hasStockMovement"
      @close="cancelOrderVisible = !cancelOrderVisible"
      @confirmed="$router.push(indexPath)"
    />
    <disable-order-invoice-confirmation
      :visible="disableOrderInvoiceVisible"
      :order-id="order.data.id"
      :with-loading="false"
      @submit="loadingDisableInvoice = true"
      @close="disableOrderInvoiceVisible = !disableOrderInvoiceVisible"
      @confirmed="onDisabledInvoice"
    />
    <delete-shipment-confirmation
      :visible="deleteVisible"
      :stock-movement-id="stockMovement ? stockMovement.id : null"
      @close="deleteVisible = false"
      @confirmed="onDeleted"
    />
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="5000"
      :filename="order.data.attributes.origin_code"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
      ref="html2Pdf"
    >
      <PdfContent slot="pdf-content" :order="order" />
    </vue-html2pdf>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="5000"
      :filename="order.data.attributes.origin_code"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
      ref="printStockMovementEl"
    >
      <LayoutPrintStockMovementReceipt
        slot="pdf-content"
        :order="{
          code: order.data.attributes.origin_code,
          date: order.data.attributes.orderedAt || order.data.attributes.createdAt,
          destinationOfficeCode: destinationOffice
            ? destinationOffice.attributes.code
            : '-',
          destinationOfficeName: destinationOffice
            ? destinationOffice.attributes.name
            : '-',
          destinationOfficeAddress: destinationOffice
            ? destinationOffice.attributes.address
            : '-',
          details: getIncludedByType(order, 'order-details').map((detail) => ({
            product_code: detail.attributes.product_code,
            product_qty: detail.attributes.product_qty,
            product_name: detail.attributes.product_name,
          })),
        }"
      />
    </vue-html2pdf>

    <validate-shipment-confirmation :order="order" :stock-movement="stockMovement" :visible="validateVisible" @close="validateVisible = false" @validated="$router.push(indexPath)" />
  </base-wrapper>
</template>
<script>
import ShipmentOrderDetailCard from '@/components/shipment/shipment-order-detail-card.vue';
import CancelOrderShipmentConfirmation from '@/components/order/cancel-order-shipment-confirmation.vue';
import DisableOrderInvoiceConfirmation from '@/components/order/disable-order-invoice-confirmation.vue';
import DeleteShipmentConfirmation from '@/components/stock-movement/delete-shipment-confirmation.vue';
import VueHtml2pdf from 'vue-html2pdf';
import PdfContent from '@/layouts/LayoutPrintA4.vue';
import { requestMixin } from '@/mixins/request/request';
import LayoutPrintStockMovementReceipt from '@/layouts/LayoutPrintStockMovementReceipt.vue';
import ValidateShipmentConfirmation from '@/components/stock-movement/validate-shipment-confirmation.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    ShipmentOrderDetailCard,
    CancelOrderShipmentConfirmation,
    DisableOrderInvoiceConfirmation,
    DeleteShipmentConfirmation,
    VueHtml2pdf,
    PdfContent,
    LayoutPrintStockMovementReceipt,
    ValidateShipmentConfirmation
  },
  props: {
    indexPath: String,
  },
  data() {
    return {
      accReason: null,
      loadingOrder: false,
      loadingDisableInvoice: false,
      loadingStockMovement: false,
      cancelOrderVisible: false,
      disableOrderInvoiceVisible: false,
      deleteVisible: false,
      validateVisible: false,
      order: {
        data: {
          attributes: {},
          relationships: {
            'destination-office': {
              data: null,
            },
            village: {
              data: null,
            },
            district: {
              data: null,
            },
            city: {
              data: null,
            },
            province: {
              data: null,
            },
          },
        },
        included: [],
      },
      stockMovement: null,
    };
  },
  computed: {
    destinationOffice() {
      const destinationOffice =
        this.order.data.relationships['destination-office'].data;

      if (!destinationOffice) {
        return null;
      }

      return this.getSingleIncluded(this.order, destinationOffice.id);
    },
    hasStockMovement() {
      return !!this.stockMovement;
    },
    hasRemainder() {
      if (!this.hasStockMovement) {
        return false;
      }

      const totalOrderDetail = this.getIncludedByType(
        this.order,
        'order-details'
      ).reduce(
        (prev, current) => prev + current.attributes.product_not_packed,
        0
      );
      const totalBonus = this.getIncludedByType(
        this.order,
        'order-detail-bonuses'
      ).reduce((prev, current) => prev + current.attributes.bonus_qty, 0);

      const shipped = this.stockMovement.attributes.packets.reduce(
        (prev, current) => {
          const shipped = current.products.reduce(
            (prev, current) => prev + current.shipped_product_qty,
            0
          );

          return prev + shipped;
        },
        0
      );

      return totalOrderDetail + totalBonus !== shipped;
    },
    loading() {
      return (
        this.loadingOrder ||
        this.loadingDisableInvoice ||
        this.loadingStockMovement
      );
    },
    products() {
      return [
        ...this.getIncludedByType(this.order, 'order-details'),
        ...this.getIncludedByType(this.order, 'order-detail-bonuses'),
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadOrder() {
      this.loadingOrder = true;

      const [res, err] = await this.request(
        `/api/v1/orders/${this.$route.params.id}`,
        {
          params: {
            include:
              'destination-office,village,district,city,province,order-details,order-detail-bonuses,origin-office,destination-warehouse',
            'fields[orders]':
              'warehouse_status,origin_code,orderedAt,createdAt,destination-office,order_shipment,shipment_address,village,district,city,province,order-details,order-detail-bonuses,print_invoice_enabled,origin-office,area_name,area_code,notes,destination-warehouse',
            'fields[offices]':
              'code,name,address,address2,phone,office_type,address_details,kta',
            'fields[villages]': 'name',
            'fields[districts]': 'name',
            'fields[cities]': 'name',
            'fields[provinces]': 'name',
            'fields[order-details]':
              'product_code,product_name,product_qty,product_price,total_price,bonus_value,product_not_packed',
            'fields[order-detail-bonuses]':
              'product_code,product_name,bonus_qty',
            'fields[warehouses]': 'code',
          },
        }
      );

      if (!err) {
        this.order = res;
      }

      this.loadingOrder = false;
    },
    async loadStockMovement() {
      this.loadingStockMovement = true;

      const [res, err] = await this.request(
        `/api/v1/orders/${this.$route.params.id}/stock-movements`,
        {
          params: {
            sort: 'id',
            'page[size]': 1,
            'fields[stock-movements]': 'shipment_type,is_valid_for_shipment,packets',
          },
        }
      );

      if (!err) {
        this.stockMovement = res.data[0];
      }

      this.loadingStockMovement = false;
    },
    loadData() {
      this.loadOrder();
      this.loadStockMovement();
    },
    async onDisabledInvoice() {
      this.disableOrderInvoiceVisible = false;

      await this.loadOrder();

      this.loadingDisableInvoice = false;

      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 0);
    },
    onDeleted() {
      this.deleteVisible = false;

      this.loadData();
    },
    onPrintReceipt() {
      setTimeout(() => this.$refs.printStockMovementEl.generatePdf(), 0);
    }
  },
  created() {
    this.loadData();
  },
};
</script>
