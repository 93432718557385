<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <base-select
          :shadow="false"
          v-model="filter.warehouse_status"
          @change="loadOrders"
          :options="[
            {
              label: 'Semua',
              value:
                'Persiapan,Diproses Gudang Pengeluaran,Diproses Gudang Pengiriman,Siap Dikemas',
            },
            {
              label: 'Diproses Gudang Pengiriman',
              value: 'Diproses Gudang Pengiriman',
            },
            {
              label: 'Persiapan',
              value: 'Persiapan',
            },
            {
              label: 'Diproses Gudang Pengeluaran',
              value: 'Diproses Gudang Pengeluaran',
            },
          ]"
        />
        <base-input
          :shadow="false"
          placeholder="Cari Kode atau Nama"
          type="search"
          debounce
          v-on:native-input="loadOrders"
          v-model="filter.search"
        />
      </div>
      <datatable
        :columns="columns"
        :total="orders.meta.page.total"
        :perPage="orders.meta.page.perPage"
        :currentPage="orders.meta.page.currentPage"
        :meta="orders.meta"
        cursor
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="order in orders.data"
            :key="order.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onClickDetail(order)"
          >
            <td :class="[classes.td]">
              <span class="block font-bold text-gray-900">{{
                order.attributes.origin_code
              }}</span>
              <span class="text-xs text-gray-500">{{
                order.attributes.updatedAt | formatDate
              }}</span>
            </td>
            <td :class="[classes.td]">
              {{
                getSingleIncluded(
                  orders,
                  order.relationships['destination-office'].data.id
                ).attributes.code
              }}
            </td>
            <td :class="[classes.td]">
              {{
                getSingleIncluded(
                  orders,
                  order.relationships['destination-office'].data.id
                ).attributes.name
              }}
            </td>
            <td :class="[classes.td]">
              {{
                getSingleIncluded(
                  orders,
                  order.relationships['destination-warehouse'].data.id
                ).attributes.code
              }}
              /
              {{
                getSingleIncluded(
                  orders,
                  order.relationships['origin-warehouse'].data.id
                ).attributes.code
              }}
            </td>
            <td :class="[classes.td]">
              {{
                getSingleIncluded(orders, order.relationships['order'].data.id)
                  .attributes.area_name
              }}
            </td>
            <td :class="[classes.td]">
              {{
                getSingleIncluded(orders, order.relationships['order'].data.id)
                  .attributes.grand_total_price_after_ppn | toCurrency
              }}
            </td>
            <td :class="[classes.td]">
              <base-badge color="indigo">{{
                getSingleIncluded(orders, order.relationships['order'].data.id)
                  .attributes.warehouse_status
              }}</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  mixins: [requestMixin],
  data() {
    return {
      loading: false,
      orders: {
        data: [],
        meta: {
          page: {},
        },
      },
      filter: {
        search: null,
        warehouse_status:
          'Persiapan,Diproses Gudang Pengeluaran,Diproses Gudang Pengiriman,Siap Dikemas',
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    columns() {
      return [
        { id: 'origin_code', name: 'Nomor Faktur' },
        { id: 'destination_office_code', name: `Kode KP` },
        { id: 'destination_office_name', name: 'Nama Pemilik' },
        { id: 'origin_warehouse_code', name: 'Kode Gudang	' },
        { id: 'area', name: 'Wilayah Harga' },
        { id: 'grand_total_price_after_ppn', name: 'Total Penjualan' },
        { id: 'status', name: 'Status Pengeluaran' },
      ];
    },
  },
  methods: {
    onChangePage(page) {
      this.loadOrders({
        ...page,
      });
    },
    onFilter() {
      this.loadOrders();
    },
    onClickDetail(simpleOrder) {
      this.$router.push({
        name: 'gudang.pengeluaran.barang-ke-kp.detail',
        params: {
          id: simpleOrder.relationships.order.data.id,
        },
      });
    },
    async loadOrders(params) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/orders', {
        params: {
          'page[limit]': 5,
          'filter[destination_office_category_id]': 2,
          'filter[origin_office_id]': this.me.office_id,
          'filter[search]': this.filter.search,
          'filter[warehouse_status]': this.filter.warehouse_status,
          include:
            'destination-office,destination-warehouse,origin-warehouse,order',
          'fields[simple-orders]':
            'origin_code,updatedAt,destination-office,destination-warehouse,origin-warehouse,order',
          'fields[offices]': 'code,name',
          'fields[warehouses]': 'code',
          'fields[orders]':
            'area_name,grand_total_price_after_ppn,warehouse_status',
          ...params,
        },
      });

      if (!error) {
        this.orders = res;
      }

      this.loading = false;
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
